@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap');


body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  background-color: #f2f2f2;
  z-index: 99;
  #loading-image {
    z-index: 100;
  }
  div {
    margin: auto;
    text-align: center;
    h3 {
      color: black;
    }
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header{
  background-color: rgb(36, 36, 36);
  padding: 5px 10px;
  .title{
    display: inline-block;
    color: white;
    margin-top: 10px;
    font-size: 24px;
  }
  .logo{
    margin: 0px 50px 0 50px;
    height: 100px;
  }
}

.markdown-text{
  p > strong{
    padding-top: 15px;
  }
}

.intro{
  background-color: #f2f2f2;
  padding: 20px;
}

.intro-header{
  background-color: black;
  img{
    margin: auto;
    display: block;
    padding: 25px 0;
  }
}

.sigCanvas{
  border: 1px solid slategray;

}

.submit-form{
  margin-bottom: 50px;
}


.language-selector{
  list-style: none;

  &-option{
    display: inline-block;
    margin: 10px 20px;
    padding: 10px;
    background-color: rgb(223, 223, 223);
    border-radius: 3px;
  }
}

.row.fields{
  h4{
    margin-top: 22px;
    margin-bottom: 0;
  }
  p{
    margin: 0;
  }
  .field-block{
    background-color: rgba(218, 218, 218, 0.541);
    border: 1px solid rgb(185, 185, 185);
    margin: 4px 2px;
    .form-control{
      border: none;
      border-radius: 0;
      transition: none;
      padding: 0.45rem 0.75rem;
    }
    label{
      margin: 0 0 0 5px;
      font-weight: 500;
    }
    
  }
  .error{
    background-color: rgba(209, 65, 40, 0.767);
    padding: 5px 10px;
    margin: -4px 0 10px 0;
    color: white;
    font-size: 12px;
  }
}

.file-upload{
  margin-top: 65px;
}

.dropzone{
  background-color: rgb(211, 211, 211);
  border: 2px dotted black;
  border-radius: 3px;
  padding: 25px 45px;
  p{
    margin: 0;
  }
}